import styled from 'styled-components';

interface StickyProps {
  sticky: boolean;
}

export const Container = styled.nav<StickyProps>`
  position: ${(props) => (props.sticky ? 'fixed' : 'absolute')};
  width: 100%;

  padding: ${(props) => (props.sticky ? '.5rem 0' : 'none')};

  color: ${(props) =>
    props.sticky ? props.theme.colorPrimary : props.theme.colorWhite};
  background-color: ${(props) =>
    props.sticky ? props.theme.colorWhite : 'transparent'};

  z-index: 999;

  box-shadow: ${(props) => (props.sticky ? props.theme.shadowDark : 'none')};

  top: ${(props) => (props.sticky ? '0' : '2rem')};
  left: 50%;

  transform: translateX(-50%);
  transition: all 0.4s;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Nav = styled.nav<StickyProps>`
  position: relative;
  z-index: 100;

  display: flex;

  justify-content: center;
  align-items: center;
`;

export const BrandLogo = styled.a`
  border-right: none;

  &:link,
  &:visited {
    color: inherit;
  }

  & svg {
    height: 6rem;
    width: 12rem;

    color: ${(props) => props.theme.colorPrimaryLight};
  }
`;

export const NavItem = styled.a<StickyProps>`
  position: relative;

  font-size: 2.2rem;

  margin: 0 4rem;

  transition: all 0.2s;

  &:hover {
    font-size: 2.4rem;

    padding: 0.5rem;
    border-radius: 2px;

    color: ${(props) =>
      props.sticky ? props.theme.colorPrimaryLight : 'inherit'};

    background-color: ${(props) =>
      props.sticky ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,.3)'};
  }

  &:active {
    transform: translateY(2px);
  }

  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  &::after {
    content: '';

    position: absolute;
    z-index: 1;

    bottom: -0.5rem;
    left: 50%;

    transform: translateX(-50%);

    height: 2px;
    width: 5rem;

    background-color: ${(props) =>
      props.sticky ? props.theme.colorPrimaryDark : props.theme.colorWhite};
  }
`;
