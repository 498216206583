import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go';

import * as Component from '../styles/components/contact';
import * as Typography from '../styles/typography';
import * as Reusable from '../styles/reusable';

const Contact = () => {
  const [emailStatus, setEmailStatus] = useState({
    showStatus: false,
    success: false
  });
  const formRef = useRef<HTMLFormElement>(null);

  const sendEmail = async (e: any) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        'service_e0mpoai',
        'template_81000z9',
        // @ts-ignore
        formRef.current,
        'ppTor_SK2wvg_XNK5'
      );
    } catch (error) {
      setEmailStatus({ showStatus: true, success: false });
      setTimeout(() => {
        setEmailStatus({ showStatus: false, success: false });
      }, 4000);
      return;
    }

    formRef.current?.reset();
    setEmailStatus({ showStatus: true, success: true });
    setTimeout(() => {
      setEmailStatus({ showStatus: false, success: true });
    }, 4000);
  };

  return (
    <Component.Container id='contact'>
      <Typography.HeaderPrimaryDark>Contact Us</Typography.HeaderPrimaryDark>
      <Component.ContactInfo>
        <Component.ContactDetails>
          <Typography.HeaderSecondary>Contact Info</Typography.HeaderSecondary>
          <Component.ContactDetailsInfo>
            <Typography.HeaderTertiary>Call us</Typography.HeaderTertiary>
            <span>
              <AiOutlinePhone />
              (208)406-6977
            </span>
            <Typography.HeaderTertiary>Location</Typography.HeaderTertiary>
            <span>
              <GoLocation />
              330 S Ammon Rd. Ammon, Idaho 83406
            </span>
            <Typography.HeaderTertiary>E-Mail us</Typography.HeaderTertiary>
            <span>
              <AiOutlineMail />
              nathan.nelson@citycreekproperties.com
            </span>
          </Component.ContactDetailsInfo>
        </Component.ContactDetails>

        <Component.ContactFormContainer>
          <Typography.HeaderSecondary>Message us</Typography.HeaderSecondary>

          <Component.ContactForm
            ref={formRef}
            name='contact_form'
            onSubmit={(e) => sendEmail(e)}
          >
            <Component.ContactFormGroup>
              <Component.ContactFormLabel htmlFor='name'>
                Full Name
              </Component.ContactFormLabel>
              <Component.ContactFormInput
                type='text'
                required={true}
                placeholder='ex. John Doe'
                id='name'
                name='name'
              />
            </Component.ContactFormGroup>
            <Component.ContactFormGroup>
              <Component.ContactFormLabel htmlFor='email'>
                Email
              </Component.ContactFormLabel>
              <Component.ContactFormInput
                type='email'
                required={true}
                placeholder='ex. example@example.com'
                id='email'
                name='email'
              />
            </Component.ContactFormGroup>
            <Component.ContactFormGroup>
              <Component.ContactFormLabel htmlFor='message'>
                Your message
              </Component.ContactFormLabel>
              <Component.ContactFormTextarea
                required={true}
                placeholder='Reach out to us here'
                id='message'
                name='message'
              />
            </Component.ContactFormGroup>
            <Reusable.ButtonPrimary>Send Message</Reusable.ButtonPrimary>
          </Component.ContactForm>
          <Component.ContactFormStatus
            visible={emailStatus.showStatus ? true : false}
            success={emailStatus.success ? true : false}
          >
            {emailStatus.success ? (
              <p>
                Your email was successfully sent, we'll get back to you ASAP!
              </p>
            ) : (
              <p>
                Uh oh! There was a problem sending your message, please try
                again
              </p>
            )}
          </Component.ContactFormStatus>
        </Component.ContactFormContainer>
      </Component.ContactInfo>
    </Component.Container>
  );
};

export default Contact;
