import React, { useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { BsChevronRight } from 'react-icons/bs';

import * as Components from '../styles/components/gallery';
import * as Typography from '../styles/typography';
import * as Reusable from '../styles/reusable';

import kitSm1 from '../assets/img/gallery/kitSm1.webp';
import kitSm2 from '../assets/img/gallery/kitSm2.webp';
import kitSmAft from '../assets/img/gallery/kitSmAft.webp';
import kitBg1 from '../assets/img/gallery/kitBg1.webp';
import kitBg2 from '../assets/img/gallery/kitBg2.webp';
import kitBg3 from '../assets/img/gallery/kitBg3.webp';
import kitBgAft1 from '../assets/img/gallery/kitBgAft1.webp';
import kitBgAft2 from '../assets/img/gallery/kitBgAft2.webp';
import bathBlue from '../assets/img/gallery/bathBlue.webp'
import bathBlue1 from '../assets/img/gallery/bathBlue1.webp';
import bathBlueAftFull from '../assets/img/gallery/bathBlueAftFull.webp';
import bathBlueAftProg from '../assets/img/gallery/bathBlueAftProg.webp';
import bathBlueAftShw from '../assets/img/gallery/bathBlueAftShw.webp';
import bathBlueAftSink from '../assets/img/gallery/bathBlueAftSink.webp';
import pipe from '../assets/img/gallery/pipe.webp';
import window from '../assets/img/gallery/window.webp'; 

const Gallery = () => {
  const [index, setIndex] = useState(7);
  const [subIndex, setSubIndex] = useState(3);
  const [beforeImgList] = useState([
    kitBg1,
    kitBg2,
    kitBg3,
    bathBlue,
    bathBlue,
    bathBlue,
    kitSm1,
    kitSm2,
  ]);
  const [afterImgList] = useState([
    kitBgAft1,
    kitBgAft2,
    kitBgAft1,
    bathBlueAftFull,
    bathBlueAftShw,
    bathBlueAftSink,
    kitSmAft,
    kitSmAft,
  ]);
  const [otherImgList] = useState([
    bathBlueAftProg,
    bathBlue1,
    pipe,
    window,
  ]);

  const onClickNext = ({length, idx, type = 'index'}: {length: number, idx: number, type: string}) => {
    if (idx + 1 === length) {
      type === "index" ? setIndex(0): setSubIndex(0);
    } else {
      type === "index" ? setIndex(idx + 1): setSubIndex(idx + 1);
    }
  };

  const onClickPrev = ({length, idx, type = 'index'}: {length: number, idx: number, type: string}) => {
    if (idx - 1 === -1) {
      type === "index" ? setIndex(length - 1): setSubIndex(length - 1);
    } else {
      type === "index" ? setIndex(idx - 1): setSubIndex(idx - 1);
    }
  };

  const renderImages = (type: 'before' | 'after' | 'other') => {
    if (type === 'before') {
      return beforeImgList.map((img, i) => {
        let dif = i - index;

        return (
          <Components.Img
            key={`Before ${i}`}
            alt={`Home remodeling and/or projects before ${i}`}
            src={img}
            style={{ transform: `translateX(${-100 * dif}%)` }}
          />
        );
      });
    } else if(type === 'after') {
      return afterImgList.map((img, i) => {
        let dif = i - index;

        return (
          <Components.Img
            key={`After ${i}`}
            alt={`Home remodeling and/or projects after ${i}`}
            src={img}
            style={{ transform: `translateX(${-100 * dif}%)` }}
          />
        );
      });
    } else {
      return otherImgList.map((img, i) => {
        let dif = i - subIndex;

        return (
          <Components.SubImg
            key={`After ${i}`}
            alt={`Home remodeling and/or projects other ${i}`}
            src={img}
            style={{ transform: `translateX(${-100 * dif}%)` }}
          />
        );
      });
    }
  };

  return (
    <Components.Container id='gallery'>
      <Typography.HeaderPrimaryDark>
        Project Gallery
      </Typography.HeaderPrimaryDark>
      <Components.Gallery>
        <Reusable.ButtonArrow
          aria-label='Gallery move left'
          direction='left'
          onClick={() => onClickNext({length: beforeImgList.length, idx: index, type: 'index'})}
        >
          <BsChevronLeft />
        </Reusable.ButtonArrow>
        <Components.ImgTextContainer>
          <Typography.HeaderTertiary>Before</Typography.HeaderTertiary>
          <Components.ImgContainer>
            {renderImages('before')}
          </Components.ImgContainer>
        </Components.ImgTextContainer>
        <Reusable.VertLine
          className='gallery_line'
          height='15rem'
          type='primary'
        />
        <Components.ImgTextContainer>
          <Typography.HeaderTertiary className='second-heading'>
            After
          </Typography.HeaderTertiary>
          <Components.ImgContainer>
            {renderImages('after')}
          </Components.ImgContainer>
        </Components.ImgTextContainer>
        <Reusable.ButtonArrow
          aria-label='Gallery move right'
          direction='right'
          onClick={() => onClickPrev({length: beforeImgList.length, idx: index, type: 'index'})}
        >
          <BsChevronRight />
        </Reusable.ButtonArrow>
      </Components.Gallery>

      <Typography.HeaderPrimaryDarkSm>Other work</Typography.HeaderPrimaryDarkSm>
      <Components.SubGallary>
      <Reusable.ButtonArrow className='button-sub'
          aria-label='Gallery move left'
          direction='left'
          onClick={() => onClickNext({length: otherImgList.length, idx: subIndex, type: 'sub'})}
        >
          <BsChevronLeft />
        </Reusable.ButtonArrow>
        <Components.SubImgContainer >
            {renderImages('other')}
          </Components.SubImgContainer>
        <Reusable.ButtonArrow
          className='button-sub'
          aria-label='Gallery move right'
          direction='right'
          onClick={() => onClickPrev({length: otherImgList.length, idx: subIndex, type: 'sub'})}
        >
          <BsChevronRight />
        </Reusable.ButtonArrow>
      </Components.SubGallary>
    </Components.Container>
  );
};

export default Gallery;
