import styled from 'styled-components';

interface OpenProps {
  open: boolean;
}

export const Container = styled.nav<OpenProps>`
  position: fixed;

  z-index: 999;

  top: 45%;
  right: 0;

  height: 100%;
  width: ${(props) => (props.open ? '100%' : '0%')};

  opacity: ${(props) => (props.open ? '1' : '0')};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};

  transform: translateY(-50%);

  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
`;

export const Background = styled.div<OpenProps>`
  position: fixed;

  z-index: 999;

  top: 50%;
  right: 0;

  height: 100%;
  width: 5%;

  transition: all 0.5s;

  background: radial-gradient(
    ${(props) => props.theme.colorPrimaryLight},
    ${(props) => props.theme.colorPrimaryDark}
  );

  transform: translate(3rem, -50%)
    ${(props) => (props.open ? 'scaleX(60)' : 'scaleX(0)')};
`;

export const NavButton = styled.button<OpenProps>`
  position: fixed;

  top: 2rem;
  right: 2rem;

  z-index: 1100;

  background-color: ${(props) => props.theme.colorWhite};
  outline: none;
  border: none;

  padding: 1.5rem;
  border-radius: 50%;

  box-shadow: ${(props) => props.theme.shadowDark};

  backface-visibility: hidden;

  transform: ${(props) => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};

  transition: transform 0.5s;

  &:focus {
    border: 4px solid ${(props) => props.theme.colorPrimary};
  }

  & svg {
    font-size: 3.5rem;
    color: ${(props) => props.theme.colorPrimaryLight};
  }
`;

export const Nav = styled.div<OpenProps>`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  position: absolute;

  right: 0;

  height: 100%;
  width: 100%;

  transform: scaleX();
`;

export const NavItem = styled.a`
  &:link,
  &:visited {
    text-decoration: none;
    border: none;

    font-size: 2.8rem;

    color: ${(props) => props.theme.colorWhite};

    padding: 0.1rem 1rem;
    margin: 0 5rem 0;

    transition: all 0.3s;

    &:hover,
    &:focus {
      outline: none;

      color: ${(props) => props.theme.colorPrimary};
      background-color: ${(props) => props.theme.colorWhite};

      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(2px);
    }
  }
`;

export const BrandLogo = styled.a`
  &:link,
  &:visited {
    color: inherit;
  }

  & svg {
    height: 6rem;
    width: 12rem;
  }
`;
