import styled from 'styled-components';

import propMediumBg from '../../assets/img/property-management-medium.webp';
import landscapingMediumBg from '../../assets/img/landscaping-medium.webp';
import repairMediumBg from '../../assets/img/repair-medium.webp';
import remodelMediumBg from '../../assets/img/remodel-medium.webp';
import { device } from '../devices';

interface CardProps {
  image: string;
  active: boolean;
}

export const Container = styled.div`
  position: relative;

  width: 60%;
  min-height: 45rem;

  transition: all 0.4s;

  @media ${device.laptopL} {
    width: 70%;
    min-height: 48rem;
  }
  @media ${device.laptop} {
    width: 80%;
    min-height: 50rem;
  }
  @media ${device.tabletS} {
    width: 90%;
  }
`;

export const Card = styled.div<CardProps>`
  position: absolute;

  width: 100%;
  height: 100%;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  color: ${(props) => props.theme.colorWhite};

  background-color: ${(props) => props.theme.colorPrimaryDark};

  background-color: ${(props) => props.theme.colorWhite};
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.7) 100%,
      rgba(0, 0, 0, 0.7) 100%
    ),
    ${(props) => {
      switch (props.image) {
        case 'property':
          return `url(${propMediumBg})`;
        case 'landscaping':
          return `url(${landscapingMediumBg})`;
        case 'repair':
          return `url(${repairMediumBg})`;
        case 'remodel':
          return `url(${remodelMediumBg})`;
        default:
          return `url(${propMediumBg})`;
      }
    }};

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 0.5rem;

  display: flex;
  opacity: ${(props) => (props.active ? 1 : 0)};

  z-index: ${(props) => (props.active ? 0 : -1)};

  flex-direction: column;

  justify-content: center;
  align-items: center;

  transition: all 0.3s;
`;

export const CardDesc = styled.p`
  width: 70%;

  font-size: 2.1rem;
  text-align: center;

  margin-top: 4rem;

  overflow: scroll;

  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  &::-webkit-scrollbar {
    width: 15px;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a8bbbf;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  @media ${device.tabletS} {
    margin-top: 1rem;
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;
