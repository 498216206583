import styled from 'styled-components';
import { device } from '../devices';

export const Container = styled.footer`
  width: 100%;
  min-height: 25vh;

  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorTertiary};

  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: column;

  position: relative;

  padding: 5rem 0;
`;

export const Cover = styled.img`
  width: 38rem;
  height: auto;
`;

export const InfoContainer = styled.div`
  padding: 5rem 0;

  width: 100%;
  min-height: 25vh;

  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorTertiary};

  display: flex;

  justify-content: center;
  align-items: center;

  @media ${device.tabletS} {
    flex-direction: column;
  }
`;

export const LinksContainer = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  @media ${device.tabletS} {
    flex-direction: column;

    &:nth-child(2) {
      margin-top: 3rem;
    }
  }
`;

export const LinksHeader = styled.h2`
  font-size: 3rem;
  font-weight: ${(props) => props.theme.lightFontWeight};

  height: 5rem;
  width: 13rem;

  margin-right: -1rem;

  text-align: center;

  color: ${(props) => props.theme.colorPrimary};

  transform: rotate(-90deg);

  position: relative;

  &::after {
    content: '';

    height: 2px;
    width: 50%;

    background-color: ${(props) => props.theme.colorPrimaryLight};

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);
  }

  @media ${device.tabletS} {
    width: 18rem;

    transform: rotate(0deg);
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  font-size: 1.8rem;

  position: relative;
  z-index: 1;

  & a {
    transition: all 0.3s;

    margin: 0.2rem 0;
    padding: 0.2rem;

    text-align: center;

    &:link,
    &:visited {
      color: inherit;
      text-decoration: none;
    }

    &:hover {
      color: ${(props) => props.theme.colorPrimaryDark};
      background-color: ${(props) => props.theme.colorWhite};

      box-shadow: ${(props) => props.theme.shadowDark};

      transform: translateY(-2px);
    }

    &:active {
      transform: translateY(1px);
    }

    & svg {
      font-size: 2rem;
      margin-right: 0.3rem;
    }
  }
`;

export const Legal = styled.div`
  width: 100%;
  position: absolute;

  text-align: center;

  opacity: 0.5;

  bottom: 0;
  left: 50%;

  transform: translateX(-50%);

  font-style: italic;
`;
