import React, { useEffect, useState } from 'react';

import * as Components from '../styles/components/navbar';
import * as Reusable from '../styles/reusable';
import { ReactComponent as NavLogo } from '../assets/img/nav-logo.svg';

const Navbar = () => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);

    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 300 ? setSticky(true) : setSticky(false);
    }
  };

  return (
    <Components.Container sticky={sticky}>
      <Components.Nav sticky={sticky}>
        <Components.NavItem sticky={sticky} href='#services'>
          Services
        </Components.NavItem>
        <Reusable.VertLine type={sticky ? 'primary' : ''} />
        <Components.NavItem sticky={sticky} href='#gallery'>
          Gallery
        </Components.NavItem>
        <Components.BrandLogo href='#hero'>
          <NavLogo />
        </Components.BrandLogo>
        <Components.NavItem sticky={sticky} href='#testimonials'>
          Testimonials
        </Components.NavItem>
        <Reusable.VertLine type={sticky ? 'primary' : ''} />

        <Components.NavItem sticky={sticky} href='#contact'>
          Contact
        </Components.NavItem>
      </Components.Nav>
    </Components.Container>
  );
};

export default Navbar;
