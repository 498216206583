import styled from 'styled-components';
import { device } from '../devices';

interface ImgProps {
  visible?: string;
}

export const Container = styled.section`
  background-color: ${(props) => props.theme.colorWhite};
  border-radius: 2px;

  margin-top: 16rem;
  padding-top: 5rem;
  padding-bottom: 18rem;

  transform: skewY(-7deg);

  display: flex;
  flex-direction: column;

  justify-content: start;
  align-items: center;

  & > * {
    transform: skewY(7deg);
  }

  @media ${device.tablet} {
    padding-bottom: 10rem;
  }
`;

export const Gallery = styled.div`
  margin: 3rem 0 8rem 0;
  position: relative;

  display: flex;

  justify-content: space-around;
  align-items: center;

  & > * {
    &:not(:first-child) {
      margin-left: 5rem;

      @media ${device.laptop} {
        margin-left: 2rem;
      }
      @media ${device.tablet} {
        margin-left: 0;
      }
    }
  }

  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;

    margin-bottom: 2rem;

    & .gallery_line {
      transform: rotate(90deg);
      margin: -5rem 0;

      @media ${device.tabletS} {
        margin: -3rem 0;
      }
    }
  }
`;

export const ImgTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    & h3.second-heading {
      order: 2;
    }
  }
`;

export const ImgContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  overflow: hidden;

  width: 45rem;
  height: 35rem;

  border-bottom: 6px solid ${(props) => props.theme.colorPrimary};
  box-shadow: 0px 2.3rem 1.1rem -1.6rem rgba(0,0,0,0.2);

  @media ${device.laptopL} {
    width: 35rem;
    height: 30rem;
  }
  @media ${device.tablet} {
    width: 45rem;
    height: 40rem;
  }
  @media ${device.mobileL} {
    width: 37rem;
    height: 33rem;
  }
  @media ${device.mobileS} {
    width: 33rem;
    height: 30rem;
  }
`;

export const Img = styled.img<ImgProps>`
  position: absolute;

  width: 45rem;
  height: 35rem;

  border-radius: 0.2rem;

  transition: all 0.2s;

  @media ${device.laptopL} {
    width: 35rem;
    height: 30rem;
  }
  @media ${device.tablet} {
    width: 45rem;
    height: 40rem;
  }
`;

export const SubGallary = styled.div`
  margin-top: 2rem;

  display: flex;
  align-items: center;

  position: relative;

  & .button-sub {
    display: block;
    margin: 3rem;

    z-index: 10;
  }


  @media ${device.tablet} {
    flex-direction: column;
    justify-content: center;

    flex-wrap: wrap;
    margin-bottom: 10rem;

    & .button-sub {
      margin-top: 25rem;

      margin-left: 10rem;
      margin-right: 10rem;
    }
  }

  @media ${device.tabletS} {

    & .button-sub {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }
  @media ${device.mobileL} {
    margin-bottom: 7rem;

    & .button-sub {
      margin-top: 22rem;
    }
  }
`

export const SubImgContainer = styled(ImgContainer)`
  width: 60rem;

  @media ${device.laptopL} {
    width: 50rem;
  }
  @media ${device.tablet} {
    width: 45rem;
  }
  @media ${device.mobileL} {
    width: 37rem;
  }
  @media ${device.mobileS} {
    width: 33rem;
  }
`

export const SubImg = styled(Img)`
  width: 60rem;

  @media ${device.laptopL} {
    width: 50rem;
  }
  @media ${device.tablet} {
    width: 45rem;
    height: 40rem;
  }
`
