import React from 'react';
import { ThemeProvider } from 'styled-components';
import { useMediaQuery } from 'react-responsive';

//Import global styles
import GlobalStyle from '../styles/globalStyles';
import theme from '../styles/theme';
import { device } from '../styles/devices';

import Hero from './hero';
import Services from './services';
import Gallery from './gallery';
import Testimonials from './testimonials';
import Contact from './contact';
import Footer from './footer';
import Navbar from './navbar';
import NavbarMobile from './navbar-mobile';

const App = () => {
  const isTablet = useMediaQuery({ query: device.tabletL });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {isTablet ? <NavbarMobile /> : <Navbar />}
      <Hero />
      <Services />
      <Gallery />
      <Testimonials />
      <Contact />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
