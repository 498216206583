import styled from 'styled-components';
import { device } from '../devices';

interface TabButtonProps {
  active: boolean;
}

export const Container = styled.section`
  display: flex;
  flex-direction: column;

  justify-content: start;
  align-items: center;

  margin-top: -10vh;

  @media ${device.tabletS} {
    margin-bottom: -8rem;
  }
  @media ${device.mobileL} {
    margin-top: -6vh;
  }
`;

export const Description = styled.p`
  max-width: 50%;

  padding: 1rem 3rem;
  margin-top: 1rem;

  font-size: 1.8rem;
  text-align: center;

  border-radius: 1rem;
  box-shadow: ${(props) => props.theme.shadowDark};

  background-color: ${(props) => props.theme.colorWhite};
  color: ${(props) => props.theme.colorBlack};

  @media ${device.desktop} {
    max-width: 60%;
  }
  @media ${device.laptopL} {
    max-width: 70%;
  }
  @media ${device.tabletS} {
    max-width: 90%;
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  overflow: scroll;

  padding: 0 1.5rem;

  margin-top: 5rem;
  margin-bottom: 5rem;

  max-width: 75%;

  & > * {
    &:not(:first-child) {
      margin-left: 2rem;
    }
  }

  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 20px;
    border: 0px solid transparent;
    background-clip: content-box;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  @media ${device.laptopL} {
    margin: 3rem 0;

    box-shadow: ${(props) => props.theme.shadowDark};
    background-color: rgba(255, 255, 255, 0.1);

    border-radius: 3px;
    padding: 0.5rem 1.5rem;
  }

  @media ${device.tabletS} {
    max-width: 90%;
  }
`;

export const TabButton = styled.button<TabButtonProps>`
  //Reset
  font-family: inherit;
  border: none;
  padding: none;
  text-decoration: none;
  background: inherit;
  color: inherit;

  //Styles
  font-size: 2.4rem;
  padding: 0.5rem 1.5rem;

  white-space: nowrap;

  background-color: transparent;

  border: ${(props) =>
    props.active ? `1px solid ${props.theme.colorPrimary}` : 'none'};

  color: ${(props) => props.theme.colorPrimary};
  border-radius: 3px;

  transition: all 0.2s;

  &:hover {
    cursor: pointer;

    border: 1px solid ${(props) => props.theme.colorPrimary};

    transform: scale(1.1);
  }

  &:active {
    transform: translateY(3px) scale(1.1);
  }

  @media ${device.laptopL} {
    font-size: 2.2rem;

    padding: 0.3rem 1rem;
  }
`;
