import React, { useState } from 'react';
import { CgMenuRight, CgClose } from 'react-icons/cg';

import { ReactComponent as NavLogo } from '../assets/img/nav-logo.svg';

import * as Component from '../styles/components/navbar-mobile';

const NavbarMobile = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Component.NavButton
        aria-label='Menu button'
        open={open}
        onClick={() => (open ? setOpen(false) : setOpen(true))}
      >
        {open ? <CgClose /> : <CgMenuRight />}
      </Component.NavButton>
      <Component.Background open={open} />
      <Component.Container open={open}>
        <Component.Nav open={open}>
          <Component.BrandLogo
            href='#hero'
            onClick={() => (open ? setOpen(false) : setOpen(true))}
          >
            <NavLogo />
          </Component.BrandLogo>
          <Component.NavItem
            href='#services'
            onClick={() => (open ? setOpen(false) : setOpen(true))}
          >
            Services
          </Component.NavItem>
          <Component.NavItem
            href='#gallery'
            onClick={() => (open ? setOpen(false) : setOpen(true))}
          >
            Gallery
          </Component.NavItem>

          <Component.NavItem
            href='#testimonials'
            onClick={() => (open ? setOpen(false) : setOpen(true))}
          >
            Testimonials
          </Component.NavItem>
          <Component.NavItem
            href='#contact'
            onClick={() => (open ? setOpen(false) : setOpen(true))}
          >
            Contact
          </Component.NavItem>
        </Component.Nav>
      </Component.Container>
    </>
  );
};

export default NavbarMobile;
