import React from 'react';

import * as Component from '../styles/components/hero';
import * as Reusable from '../styles/reusable';

const Hero = () => {
  return (
    <Component.Container id='hero'>
      <Component.Title>City Creek Properties</Component.Title>
      <Component.Description>
        Your project is our <span>passion</span>. Our team of highly experienced
        professionals is ready to take on <span>any</span> job.
      </Component.Description>
      <Component.CTASpan>Work with us today</Component.CTASpan>
      <Component.CTAButtons>
        <Reusable.ButtonPrimaryLink href='#services'>
          Learn more
        </Reusable.ButtonPrimaryLink>
        <Reusable.ButtonSecondaryLink href='#contact'>
          Contact us
        </Reusable.ButtonSecondaryLink>
      </Component.CTAButtons>
    </Component.Container>
  );
};

export default Hero;
