import React, { useRef, useState } from 'react';

import * as Component from '../../styles/components/services';
import * as Typography from '../../styles/typography';
import * as Reusable from '../../styles/reusable';

import ServiceCards from './service-cards';

export type tabState =
  | 'property_management'
  | 'landscaping'
  | 'home_repair'
  | 'home_remodel';

const Services = () => {
  const [activeTab, setActiveTab] = useState<tabState>('home_repair');
  const descRef = useRef<HTMLDivElement>(null);

  const onClick = (tab: tabState) => {
    setActiveTab(tab);

    descRef?.current?.scrollIntoView({ block: 'start' });
  };

  return (
    <Component.Container id='services'>
      <Typography.HeaderPrimary>Services</Typography.HeaderPrimary>
      <Component.Description ref={descRef}>
        {' '}
        Experts at home repair, home remodeling, landscaping and sprinkers,
        property management, and much more. We've got all of your bases covered!{' '}
      </Component.Description>
      <Component.Tabs>
        <Component.TabButton
          active={activeTab === 'home_repair' ? true : false}
          onClick={() => onClick('home_repair')}
        >
          Home Repair
        </Component.TabButton>
        <Reusable.VertLine />
        <Component.TabButton
          active={activeTab === 'home_remodel' ? true : false}
          onClick={() => onClick('home_remodel')}
        >
          Home Remodeling
        </Component.TabButton>
        <Reusable.VertLine />
        <Component.TabButton
          active={activeTab === 'landscaping' ? true : false}
          onClick={() => onClick('landscaping')}
        >
          Landscaping
        </Component.TabButton>
        <Reusable.VertLine />
        <Component.TabButton
          active={activeTab === 'property_management' ? true : false}
          onClick={() => onClick('property_management')}
        >
          Property Management
        </Component.TabButton>
      </Component.Tabs>
      <ServiceCards activeTab={activeTab} />
    </Component.Container>
  );
};

export default Services;
