import React from 'react';

import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { GoLocation } from 'react-icons/go';
import { BsFacebook } from 'react-icons/bs';

import * as Component from '../styles/components/footer';
import Cover from '../assets/img/cover.webp';

const Footer = () => {
  return (
    <Component.Container>
      <Component.Cover src={Cover} alt='City creek properties cover' />
      <Component.InfoContainer>
        <Component.LinksContainer>
          <Component.LinksHeader>Navigation</Component.LinksHeader>
          <Component.Links>
            <a href='#services'># Services</a>
            <a href='#gallery'># Gallery</a>
            <a href='#testimonials'># Testimonials</a>
            <a href='#contact'># Contact Us</a>
          </Component.Links>
        </Component.LinksContainer>
        <Component.LinksContainer>
          <Component.LinksHeader>Contact</Component.LinksHeader>
          <Component.Links>
            <a href='Tel: 208-406-7890'>
              <AiOutlinePhone />
              (208)406-6977
            </a>
            <a href='mailto: nathan.nelson@citycreekproperties.com'>
              <AiOutlineMail />
              nathan.nelson@citycreekproperties.com
            </a>
            <a
              href='https://goo.gl/maps/MM6u58Fcu84WPw2QA'
              target='_blank'
              rel='noreferrer'
            >
              <GoLocation />
              330 S Ammon Rd, Ammon ID 83406
            </a>
            <a
              href='https://www.facebook.com/profile.php?id=100054286759927'
              target='_blank'
              rel='noreferrer'
            >
              <BsFacebook />
              Facebook
            </a>
          </Component.Links>
        </Component.LinksContainer>
      </Component.InfoContainer>
      <Component.Legal>
        @2022 CityCreekProperties, LLC. All rights reserved
      </Component.Legal>
    </Component.Container>
  );
};

export default Footer;
