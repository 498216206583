import styled from 'styled-components';

import mediumBg from '../../assets/img/hero-large.webp';
import { device } from '../devices';

export const Container = styled.section`
  position: relative;

  overflow: hidden;

  height: 100vh;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  color: ${(props) => props.theme.colorWhite};

  z-index: 0;

  &::before {
    content: '';

    position: absolute;

    left: 0;
    top: 0;
    right: 0;

    height: 85vh;

    padding: 0 2rem;

    background-color: ${(props) => props.theme.colorWhite};
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.6) 100%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      url(${mediumBg});

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 85vh;

    border-radius: 2px;

    z-index: -1;

    @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
      height: 95vh;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
      clip-path: polygon(0 0, 100% 0, 100% 75vh, 0 100%);
    }

    @media ${device.mobileL} {
      @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
        height: 95vh;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: 7rem;
  line-height: 7.1rem;

  font-weight: ${(props) => props.theme.lightFontWeight};

  letter-spacing: 2px;

  text-shadow: ${(props) => props.theme.shadowLight};

  margin-top: -6rem;

  @media ${device.tabletS} {
    margin-top: -12rem;

    text-align: center;
    margin-bottom: 1rem;
  }

  @media ${device.mobileL} {
    margin-bottom: 0;
  }
`;

export const Description = styled.p`
  font-size: 2.5rem;

  & span {
    color: ${(props) => props.theme.colorPrimaryLight};
    display: inline-block;

    transition: all 0.2s;
    &:hover {
      font-size: 2.6rem;

      transform: skewY(-3deg);
    }
  }

  @media ${device.laptopL} {
    width: 80%;
    text-align: center;
  }
`;

export const CTASpan = styled.h3`
  position: relative;

  margin-top: 12vh;

  font-size: 2.8rem;
  font-weight: ${(props) => props.theme.defaultFontWeight};

  &::after {
    content: '';

    height: 2px;

    width: 40%;

    background: ${(props) => props.theme.colorPrimaryDark};

    position: absolute;

    bottom: -1rem;
    left: 50%;

    transform: translateX(-50%);
  }

  @media ${device.tabletS} {
    margin-top: 8vh;
  }
  @media ${device.mobileL} {
    margin-top: 2vh;
  }
`;

export const CTAButtons = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 4rem;

  & a {
    &:first-of-type {
      margin-right: 2rem;
    }
  }
`;
