import styled from 'styled-components';
import { device } from '../devices';

export const Container = styled.section`
  position: relative;

  margin-top: -15rem;

  z-index: -1;

  display: flex;
  flex-direction: column;

  align-items: center;

  padding-bottom: 8rem;
  padding-top: 19rem;
`;

export const CardContainer = styled.div`
  width: 100%;

  flex-grow: 1;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 3rem;
`;

export const Card = styled.div`
  position: relative;

  width: 55%;

  padding: 2rem;
  padding-left: 2rem;

  display: flex;
  justify-content: start;
  align-items: center;

  border-radius: 0.4rem;
  box-shadow: ${(props) => props.theme.shadowLight};

  background-color: ${(props) => props.theme.colorWhite};

  transform: skewX(-12deg);

  &:not(:last-child) {
    margin-bottom: 10rem;

    @media ${device.laptopL} {
      margin-bottom: 5rem;
    }
  }

  @media ${device.laptopL} {
    width: 60%;
  }
  @media ${device.laptop} {
    width: 70%;
  }
  @media ${device.tabletS} {
    flex-direction: column;

    transform: skewX(0deg);
  }
  @media ${device.mobileL} {
    width: 90%;
  }
`;

export const CardAvatar = styled.div`
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 0 2rem;

  & svg {
    height: 12rem;
    width: 12rem;

    transform: skewX(12deg);

    @media ${device.tabletS} {
      transform: skewX(0deg);

      height: 16rem;
      width: 16rem;
    }
  }
`;

export const CardContent = styled.p`
  font-size: 1.6rem;
  margin-top: 1rem;

  width: 80%;

  transform: skewX(12deg);

  @media ${device.tabletS} {
    transform: skewX(0deg);

    font-size: 1.8rem;
    width: 90%;

    margin: 1rem 0;
  }
`;

export const CardName = styled.span`
  position: absolute;

  font-size: 1.9rem;

  color: ${(props) => props.theme.colorSecondary};

  top: 0.5rem;
  left: 16.5rem;

  transform: skewX(12deg);

  @media ${device.tabletS} {
    transform: skewX(0deg);
    left: 2rem;

    font-size: 2.1rem;
  }
`;

export const CardStars = styled.div`
  position: absolute;

  top: 0;
  right: 0;

  display: flex;

  justify-content: start;
  align-items: center;

  padding-right: 2rem;
  padding-top: 0.5rem;

  & svg {
    font-size: 2.3rem;
    color: ${(props) => props.theme.colorPrimary};

    transform: skewX(12deg);

    @media ${device.tabletS} {
      transform: skewX(0deg);

      font-size: 2.5rem;
    }
  }
`;
