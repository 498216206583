import React from 'react';
import { AiFillStar } from 'react-icons/ai';

import { ReactComponent as MaleIcon } from '../assets/icons/male-avatar.svg';
// import { ReactComponent as FemaleIcon } from '../assets/icons/female-avatar.svg';

import * as Component from '../styles/components/testimonials';
import * as Typography from '../styles/typography';

const Testimonials = () => {
  return (
    <Component.Container id='testimonials'>
      <Typography.HeaderPrimary>Testimonials</Typography.HeaderPrimary>
      <Component.CardContainer>
        <Component.Card>
          <Component.CardStars>
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </Component.CardStars>
          <Component.CardAvatar>
            <MaleIcon />
          </Component.CardAvatar>
          <Component.CardName>Garret Wood</Component.CardName>
          <Component.CardContent>
            {' '}
            Excellent job, Mr. Nathan is very quick and knowledgable.{' '}
          </Component.CardContent>
        </Component.Card>
        <Component.Card>
          <Component.CardStars>
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </Component.CardStars>
          <Component.CardAvatar>
            <MaleIcon />
          </Component.CardAvatar>
          <Component.CardName>Kent Hobbs</Component.CardName>
          <Component.CardContent>
            Nathan at City Creek is consistently prompt when I need work done.
            He does good Quality work. I am confident in giving him my business.
          </Component.CardContent>
        </Component.Card>
        <Component.Card>
          <Component.CardStars>
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
            <AiFillStar />
          </Component.CardStars>
          <Component.CardAvatar>
            <MaleIcon />
          </Component.CardAvatar>
          <Component.CardName>Clay Riggs</Component.CardName>
          <Component.CardContent>
            Nathan was super quick to respond when I had a leak in our foundation. He has been able to fix everything and put in a French drain in my backyard. 100% would recommend him.
          </Component.CardContent>
        </Component.Card>
      </Component.CardContainer>
    </Component.Container>
  );
};

export default Testimonials;
