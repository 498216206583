import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  defaultFontSize: '1.6rem',
  defaultFontWeight: '400',
  lightFontWeight: '300',
  boldFontWeight: '700',
  colorPrimaryLight: '#82DBD8',
  colorPrimaryLightRGB: '39, 102, 144',
  colorPrimary: '#3BACB6',
  colorPrimaryRGB: '32, 83, 117',
  colorPrimaryDark: '#2F8F9D',
  colorPrimaryDarkRGB: '25, 63, 90',
  colorSecondary: '#809870',
  colorTertiary: '#112B3C',
  colorLightGray1: '#faf9f9',
  colorLightGray2: '#f4f2f2',
  colorLightGray3: '#f0eeee',
  colorLightGray3RGB: '240, 238, 238',
  colorLightGray4: '#ccc',
  colorDarkGray1: '#777',
  colorDarkGray2: '#999',
  colorDarkGray2RGB: '153, 153, 153',
  colorDarkGray3: '#333',
  colorDarkGray4: '#808080',
  colorDarkGray4RGB: '128, 128, 128',
  colorWhite: '#EFEFEF',
  colorBlack: '#1f2A30',
  colorError: '#f44336',
  colorErrorRGB: '244, 67, 54',
  colorSuccess: '#42945c',
  colorSuccessRGB: '66, 148, 92',
  borderLight: `1px solid #ccc`,
  shadowLight: '.2rem .4rem .8rem rgba(0,0,0,0.3)',
  shadowVeryLight: '.2rem .2rem 1rem rgba(0,0,0,.2)',
  shadowDark: '.2rem .2rem 2rem rgba(0,0,0,.4)'
};

export default theme;
