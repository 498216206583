import React, { useEffect } from 'react';

import * as Component from '../../styles/components/serviceCards';
import * as Typography from '../../styles/typography';

import { tabState } from './index';

interface ServiceProps {
  activeTab: tabState;
}

const ServiceCard: React.FC<ServiceProps> = ({ activeTab }) => {
  useEffect(() => {});

  return (
    <Component.Container>
      <Component.Card
        image='property'
        active={activeTab === 'property_management' ? true : false}
      >
        <Typography.HeaderSecondary>
          Property Management
        </Typography.HeaderSecondary>
        <Component.CardDesc>
          {' '}
          <span style={{ fontSize: '2.5rem' }}>Professional, Proven</span>{' '}
          <br /> Experts at successfully promoting and leasing rental
          properties. We leverage professional relationships with quality
          vendors and service providers. Our accounting, and asset manegemant
          stategies are meticulously crafted to give you security and growth.
          Our staff is professional, friendly, and focused on long-term customer
          relations. We will care about your property as if it were our own.{' '}
        </Component.CardDesc>
      </Component.Card>
      <Component.Card
        image='landscaping'
        active={activeTab === 'landscaping' ? true : false}
      >
        <Typography.HeaderSecondary>Landscaping</Typography.HeaderSecondary>
        <Component.CardDesc>
          {' '}
          Quality work at affordable prices is our promise. We provide a variety
          of professional landscaping services. Our services include lawn care
          services, front yard landscaping, snow removal, house cleaning, tree
          trimming and removal, sprinkler system installation and blow out!{' '}
        </Component.CardDesc>
      </Component.Card>
      <Component.Card
        image='repair'
        active={activeTab === 'home_repair' ? true : false}
      >
        <Typography.HeaderSecondary>Home Repair</Typography.HeaderSecondary>
        <Component.CardDesc>
          {' '}
          Whether it's framing, carpentry, drywall, doors and windows, fencing,
          roof repair, or building out that dream project you've been wanting.
          Dedicated to affordablity and availability, our team is ready to
          tackle anything!
        </Component.CardDesc>
      </Component.Card>
      <Component.Card
        image='remodel'
        active={activeTab === 'home_remodel' ? true : false}
      >
        <Typography.HeaderSecondary>Home Remodeling</Typography.HeaderSecondary>
        <Component.CardDesc>
          {' '}
          It is our goal to make your dream home become reality. Whether it be a
          minor bathroom remodel or a large scale home remodel, our team has the
          skills and expertise to get your projects taken care of. Our home
          remodel experts have decades of experience, and are ready to bring
          your home to life!{' '}
        </Component.CardDesc>
      </Component.Card>
    </Component.Container>
  );
};

export default ServiceCard;
