import styled from 'styled-components';

export const HeaderPrimary = styled.h1`
  font-size: 5rem;
  line-height: 5.2rem;

  font-weight: ${(props) => props.theme.lightFontWeight};

  letter-spacing: 0.2rem;

  color: ${(props) => props.theme.colorWhite};
  text-shadow: ${(props) => props.theme.shadowLight};

  text-align: center;
`;

export const HeaderSecondary = styled.h2`
  font-size: 3.3rem;
  letter-spacing: 2px;

  text-transform: uppercase;

  text-align: center;

  line-height: 3.4rem;
`;

export const HeaderPrimaryDark = styled(HeaderPrimary)`
  color: ${(props) => props.theme.colorPrimary};
  text-shadow: ${(props) => props.theme.shadowLight};

  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colorPrimaryLight},
    ${(props) => props.theme.colorPrimaryDark}
  );
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
`;

export const HeaderPrimaryDarkSm = styled(HeaderPrimaryDark)`
    font-size: 4.3rem;
`;

export const HeaderTertiary = styled.h3`
  display: block;

  color: ${(props) => props.theme.colorPrimaryDark};

  font-weight: ${(props) => props.theme.defaultFontWeight};
  font-size: 2.8rem;
`;
