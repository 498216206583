import styled from 'styled-components';
import { device } from './devices';

interface LineProps {
  height?: string;
  type?: string;
}

interface ArrowButtonProps {
  direction: 'left' | 'right';
}

export const ButtonPrimaryLink = styled.a`
  display: inline-block;

  font-size: 1.8rem;

  letter-spacing: 1px;
  text-transform: uppercase;

  line-height: 2rem;

  text-align: center;

  padding: 1rem 2rem;

  border-radius: 10rem;
  box-shadow: ${(props) => props.theme.shadowLight};

  background-color: ${(props) => props.theme.colorPrimary};

  transition: all 0.2s;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }

  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
`;

export const ButtonPrimary = styled.button`
  display: inline-block;

  font-size: 1.8rem;

  letter-spacing: 1px;
  text-transform: uppercase;

  line-height: 2rem;

  text-align: center;

  padding: 1rem 2rem;

  border: none;
  border-radius: 10rem;
  box-shadow: ${(props) => props.theme.shadowLight};

  color: inherit;
  background-color: ${(props) => props.theme.colorPrimary};

  transition: all 0.2s;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const ButtonSecondaryLink = styled(ButtonPrimaryLink)`
  background-color: ${(props) => props.theme.colorSecondary};
`;

export const ButtonArrow = styled.button<ArrowButtonProps>`
  padding: 1rem;

  border: none;
  text-decoration: none;

  background-color: inherit;
  text-align: center;

  border-radius: 50%;

  color: ${(props) => props.theme.colorPrimary};
  transition: all 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    color: ${(props) => props.theme.colorPrimaryDark};
  }

  &:active {
    transform: ${(props) =>
      props.direction === 'right' ? 'translateX(3px)' : 'translateX(-3px)'};
  }

  & svg {
    font-size: 5rem;

    @media ${device.laptopL} {
      font-size: 4.5rem;
    }
  }

  @media ${device.tablet} {
    position: absolute;
    width: 7rem;

    top: 50%;
    transform: translateY(-50%);

    &:last-of-type {
      right: -12rem;
    }
    &:first-of-type {
      left: -12rem;
    }

    &:active {
      transform: translateY(-50%)
        ${(props) =>
          props.direction === 'right' ? 'translateX(3px)' : 'translateX(-3px)'};
    }
  }

  @media ${device.tabletS} {
    &:last-of-type {
      right: 0;
    }
    &:first-of-type {
      left: 0;
    }
  }
`;

export const VertLine = styled.div<LineProps>`
  width: 2px;
  height: ${(props) => (props.height ? props.height : '5rem')};

  padding: 0 1px;

  background-color: ${(props) =>
    props.type === 'primary'
      ? props.theme.colorPrimary
      : props.theme.colorWhite};
  display: inline-block;
`;
