import styled from 'styled-components';

import contactMediumBg from '../../assets/img/contact-medium.webp';
import { device } from '../devices';

interface FormStatusProps {
  success: boolean;
  visible: boolean;
}

export const Container = styled.section`
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;

  align-items: center;

  padding-top: 2rem;
  padding-bottom: 10rem;

  border-radius: 2px;

  background-color: ${(props) => props.theme.colorWhite};

  @media ${device.tablet} {
    height: 105rem;
  }
`;

export const ContactInfo = styled.div`
  height: 45rem;
  min-width: 67%;

  display: flex;

  justify-content: space-around;
  align-items: center;

  margin-top: 5rem;
  padding-top: 3rem;

  border-radius: 2px;
  box-shadow: ${(props) => props.theme.shadowDark};

  background-image: linear-gradient(
      105deg,
      rgba(255, 255, 255, 0.75) 0,
      rgba(255, 255, 255, 0.75) 50%,
      transparent 50%
    ),
    linear-gradient(
      105deg,
      transparent 50%,
      rgba(0, 0, 0, 0.4) 50%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    url(${contactMediumBg});

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media ${device.laptop} {
    width: 80%;
  }
  @media ${device.tabletL} {
    width: 88%;
  }
  @media ${device.tablet} {
    transform: rotate(90deg);
    margin-top: 21rem;

    width: 85rem;

    padding-top: 0;

    & > * {
      transform: rotate(-90deg);
    }
  }
  @media ${device.mobileM} {
    height: 39rem;
    margin-top: 26rem;
  }
  @media ${device.mobileS} {
    height: 35rem;
    margin-top: 28rem;
  }
`;

export const ContactDetails = styled.div`
  height: 40rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  color: ${(props) => props.theme.colorPrimaryDark};
`;

export const ContactDetailsInfo = styled.div`
  display: flex;

  margin-top: 1rem;

  flex-direction: column;
  align-items: center;

  & span {
    position: relative;
    font-size: 1.8rem;

    &:before {
      content: '';

      position: absolute;
      top: -0.5rem;
      left: 50%;

      transform: translateX(-50%);

      z-index: 10;

      background-color: ${(props) => props.theme.colorPrimaryDark};

      height: 1px;
      width: 3rem;
    }

    @media ${device.mobileS} {
      margin-top: 0;
    }
  }

  & span svg {
    position: absolute;
    top: 0;
    left: -3rem;

    font-size: 2.5rem;
  }
`;

export const ContactFormContainer = styled.div`
  width: 30rem;
  height: 40rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  color: ${(props) => props.theme.colorWhite};

  transition: all 0.3s;

  @media ${device.laptopL} {
    width: 25rem;
  }

  @media ${device.tablet} {
    padding-top: 2rem;
  }
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;

  & button {
    margin-top: 1.3rem;
  }
`;

export const ContactFormGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 0.5rem;
`;

export const ContactFormLabel = styled.label`
  font-style: italic;
`;

export const ContactFormInput = styled.input`
  height: 4rem;

  padding: 1rem;
  font-size: 1.5rem;

  border: 3px solid transparent;
  border-radius: 2px;

  transition: all 0.3s;
  &:focus {
    outline: none;

    box-shadow: ${(props) => props.theme.shadowLight};
    border-bottom: 3px solid ${(props) => props.theme.colorSuccess};

    &:invalid {
      border-bottom: 3px solid ${(props) => props.theme.colorError};
    }
  }
`;

export const ContactFormTextarea = styled.textarea`
  height: 10rem;
  width: 42rem;

  padding: 0.5rem;

  border-radius: 2px;

  font-family: inherit;
  font-size: 1.5rem;

  resize: none;

  &:focus {
    outline: none;

    box-shadow: ${(props) => props.theme.shadowLight};
    border-bottom: 3px solid ${(props) => props.theme.colorSuccess};

    &:invalid {
      border-bottom: 3px solid ${(props) => props.theme.colorError};
    }
  }

  @media ${device.laptopL} {
    width: 30rem;
  }
`;

export const ContactFormStatus = styled.div<FormStatusProps>`
  background-color: ${(props) =>
    props.success
      ? `rgba(${props.theme.colorSuccessRGB}, 0.8)`
      : `rgba(${props.theme.colorErrorRGB}, 0.8)`};

  color: ${(props) => props.theme.colorWhite};
  padding: 1rem;

  text-align: center;

  width: 55rem;
  height: 5rem;

  transition: all 0.3s;
  margin-top: 2.5rem;

  opacity: ${(props) => (props.visible ? 1 : 0)};
  transform: ${(props) => (props.visible ? 'translateY(1rem)' : 'none')};

  @media ${device.tabletL} {
    width: 40rem;
  }
  @media ${device.mobileL} {
    width: 30rem;
  }
`;
